.interview-container {
    background-color: #1d1f26;
    color: white;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    overflow: auto;
}
.interview-header {
    padding: 10px;
    padding-bottom: 0px;
}
.interview-header-div {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #fff;
}
.interview-header-item {
    display: contents;
}
.instruction-container-main {
    height: 80vh;
    display: flex;
    align-items: center;
}
.w-85vw {
    width: 85vw;
}
.instruction-container {
    display: flex;
    margin: 10px 6%;
}
.instruction-flex {
    align-items: center;
    /* height: calc(100% - 111px); */
}
.instruction-container-box {
    flex: 1;
}
.instruction-box {
    flex: none !important;
    width: 65%;
    margin-right: 10px;
}
.instruction-avatar-img {
    border: 2px solid #fff;
    border-radius: 50%;
    margin: 30px 0px;
}
.instruction-avatar-video {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 2px solid #fff;
    border-radius: 50%;
    margin: 30px 0px;
}
.instruction-avatar-text {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.227px;
}
.interview-candidate-instruction {
    color: #fff;
    font-style: normal;
    line-height: normal;
    text-align: left;
}
.instruction-title {
    font-size: 26px;
    font-weight: 700;
}
.instruction-subtitle {
    font-size: 14px;
    font-weight: 200;
    margin: 5px 0 !important;
}
.candidate-fields {
    max-width: 500px;
}
.candidate-fields label {
    font-weight: 600;
}
.candidate-input {
    box-shadow: 0px 4px 10px 0px #0000000d;
    background-color: #1d1f25;
    border: 0.5px solid #fff;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    line-height: 20.49px;
    font-weight: 400;
    color: #ffffff;
    padding: 10px 12px;
    width: 100%;
}
.candidate-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}
.react-tel-input .form-control {
    background-color: #1d1f25;
    border: 0.5px solid #fff;
    border-radius: 5px;
    box-shadow: 0 4px 10px 0 #0000000d;
    color: #fff !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.49px;
    outline: none;
    height: 42px;
}
.react-tel-input .form-control,
.react-tel-input .flag-dropdown {
    background-color: #1d1f25 !important;
    background: #1d1f25 !important;
    border-color: #fff !important;
    color: #fff !important;
}
.react-tel-input .country-list {
    height: 220px;
    margin: 0px 0 10px -1px;
    max-height: unset;
    bottom: 35px;
    background-color: #1d1f25 !important;
    background: #1d1f25 !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}
.react-tel-input .country .dial-code {
    color: #fff !important;
}
.react-tel-input .country.highlight,
.react-tel-input .country:hover {
    color: #fff !important;
    background: #343a40 !important;
}
.file-input {
    border: 0.1px dashed #fff;
    position: relative;
}
.file-input .file {
    z-index: 1;
    height: 80%;
}
.file-input .close-mark {
    top: 37.5%;
    right: 5%;
    height: 25%;
    cursor: pointer;
    z-index: 1;
}
.file-input .real-file-input {
    background-color: #1d1f25;
    color: #fff;
    overflow: hidden;
    padding: 10px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
}
.candidate-btn.disabled,
.candidate-btn:disabled {
    background: #9b9b9b !important;
}
.candidate-btn {
    padding: 6px 24px;
    border-radius: 6px;
    background: #12b6bc;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.28px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px auto;
    margin-bottom: 0px;
}
.interview-instructions {
    text-align: left;
}
.instruction-display-main-div {
    margin-top: 6px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.instruction-display-div {
    border-radius: 2px;
    background: rgba(18, 182, 188, 0.85);
    padding: 5px 15px;
    text-align: left;
    width: 50%;
    line-height: 22px;
}
.instruction-display-div img {
    margin-right: 5px;
    margin-top: -2px;
}
.instruction-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.instruction-input[type='checkbox'] {
    height: 16px;
    margin-right: 6px;
    margin-top: 1px;
    width: 16px;
}
.instruction-check {
    margin-bottom: 0.125rem;
    min-height: 1.5rem;
    justify-content: center;
    display: flex;
}
.instruction-label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    position: relative;
    max-width: 90%;
    text-align: left;
}
body {
    background: #1d1f26 !important;
    display: block;
}
.otp-section {
    max-width: 450px;
    margin: 20px 0px;
}
.otp-title {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.info-section {
    align-items: center;
    border: 1px solid #fff;
    padding: 10px;
    max-width: 450px;
}
.info-section span {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 164.1%;
}
.otp-input {
    background-color: initial;
    border-bottom: 1.5px solid #fff !important;
    border: 0 solid #fff;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    margin: 0 15px;
    text-align: center;
    width: 60px;
}
.resend-btn {
    background: none;
    text-decoration: none;
    border: none;
}
.interview-header-item img {
    max-width: 150px;
    max-height: 50px;
}
.instruction-container-box .verify-content-container {
    cursor: default;
}
.instruction-container-box .verify-content-container .blue-text {
    cursor: pointer !important;
    text-decoration: underline !important;
}
.instruction-display-div span {
    font-size: 14px;
}
@media (max-width: 768px) {
    .instruction-container-box {
        width: 100%;
        flex: none;
    }
    .mobile-hidden {
        display: none;
    }
    .instruction-title {
        font-size: 20px;
        font-weight: 600;
    }
    .instruction-display-main-div {
        display: block;
        margin: 15px;
    }
    .instruction-display-div {
        max-width: 300px;
        width: 100%;
        margin: 5px auto;
        text-align: center;
    }
    .interview-instructions,
    .interview-candidate-instruction {
        text-align: center;
    }
    .instruction-text,
    .instruction-label,
    .candidate-fields {
        text-align: left;
        margin: 0 auto;
    }
    .instruction-check {
        justify-content: left;
    }
    .instruction-input[type='checkbox'] {
        height: 14px;
        width: 14px;
        margin-top: 2px;
    }
    .candidate-btn {
        font-size: 14px;
        line-height: 30px;
        padding: 6px 16px;
        margin: 15px auto;
        width: 100%;
    }
    .instruction-display-div span {
        font-size: 13px;
    }
    .instruction-display-div img {
        width: 13px;
        margin-right: 5px;
        margin-top: -2px;
    }
    .instruction-subtitle {
        font-size: 14px;
    }
    .candidate-fields label {
        font-size: 12px;
    }
    .candidate-input,
    .file-input .real-file-input {
        padding: 12px;
        font-size: 12px;
    }
    .react-tel-input .flag-dropdown,
    .react-tel-input .form-control {
        font-size: 12px;
        height: 46px;
    }
    .interview-candidate-instruction .candidate-btn {
        width: auto !important;
        min-width: 140px;
    }
    .info-section,
    .otp-section {
        max-width: 100%;
        text-align: left;
    }
    .otp-input {
        border-bottom: 1px solid #fff !important;
        font-size: 24px;
        font-weight: 600;
        height: 45px;
        margin: 10px;
        width: 45px;
    }
    .otp-title {
        font-size: 14px;
        font-weight: 400;
    }
    .info-section p {
        font-size: 13px;
    }
    .interview-header-item img {
        max-width: 80px;
    }
    .instruction-container-main {
        display: block !important;
        height: auto !important;
    }
    .instruction-text ul {
        max-height: 240px !important;
    }
    .w-85vw {
        width: 88% !important;
    }
    .instruction-box {
        flex: none !important;
        width: 100%;
        margin-right: 0px;
    }
}
.instruction-box-mobile {
    flex: none !important;
    width: 100% !important;
    margin-right: 0px !important;
}
.candidate-input:-webkit-autofill,
.candidate-input:-webkit-autofill:focus {
    background-color: #1d1f25 !important;
    color: #fff !important;
    border: 0.5px solid #fff !important;
}
.otp-div-center {
    max-width: 450px;
    margin: 0 auto;
}

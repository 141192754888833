.profile-div,
.profile-img-div {
    align-items: center;
    display: flex;
}
.profile-div-t2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.code-snippet-header,
.screening-ques-header,
.screenshots-header {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
}

body {
    margin: 0 !important;
    font-family: Poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
}
.bullet-list {
    list-style-type: disc;
    padding-left: 10px;
    text-align: left;
}

code {
    font-family: Poppins;
}

.graph-img {
    width: 120px;
}

.feedback-footer,
.footer {
    display: flex;
}

.graph-descp-comment,
.graph-title {
    font-size: 14px;
    line-height: 20px;
}

.feedback-status {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.04em;
}

.soft-skill-header-type-1 {
    transform: rotate(270deg);
    white-space: nowrap;
}

.optional-skills-header,
.psycological-skill-header,
.soft-skill-header,
.soft-skill-header-type-2 {
    transform: rotate(90deg);
    white-space: nowrap;
}

.radar-graph-labels-main {
    text-align: left;
    width: 120px;
    margin: 0 auto;
}

.profile-div,
.profile-div-t2 {
    background: #133f7d;
    min-height: 220px;
}

.profile-img-div {
    background: #d7eeff;
    width: 220px;
    height: 240px;
    margin: 0 25px;
}

.graph-title {
    margin: 6px 0 !important;
}

.profile-img-logo {
    width: 220px;
    height: 220px;
    border-radius: 50%;
}

.common-profile-text {
    color: #fff;
    font-style: normal;
}

.common-profile-text-block {
    display: block !important;
    max-width: 786px;
}

.profile-details-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
}

.radar-graph-label-color {
    border-radius: 2px;
    height: 15px;
    margin-right: 10px;
    width: 15px;
    display: inline-block;
}

.radar-graph-label-name {
    display: inline-block;
    width: 90px;
}

.blue {
    background-color: rgba(19, 63, 125, 0.4);
}

.newgreen {
    background-color: rgba(17, 178, 184, 0.4);
}

.profile-details-status {
    position: relative;
    height: 220px;
    display: flex;
    margin-right: 5%;
    align-items: center;
}

.profile-details-status-t2 {
    position: relative;
    height: 220px;
}

.profile-contact {
    font-weight: 100;
    font-size: 15px;
    line-height: 22px;
    display: flex;
}

.profile-intId {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
}

.profile-recommended-img,
.profile-recommended-img-t2 {
    width: 200px;
    height: auto;
}
.profile-join-title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    width: 100%;
    text-align: center;
}

.profile-add-info1 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    right: 20px;
    position: absolute;
    margin-top: 5px;
    display: block;
}
.profile-add-info2 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    right: 20px;
    position: absolute;
    margin-top: 30px;
    display: block;
}

.CircularProgressbarDiv {
    float: right;
    width: 300px;
}

.profile-recommended {
    font-weight: 600;
    color: #fff !important;
    font-size: 20px;
    text-align: right;
    letter-spacing: 0.04em;
    width: 400px;
    position: absolute;
    line-height: 42.2px;
    top: 15px;
    right: 50px;
}

.detailed-feedback-title,
.feedback-header {
    font-weight: 700;
    line-height: 66px;
    text-align: center;
    color: #133f7d;
    letter-spacing: 0.04em;
    font-style: normal;
}

.profile-details-div {
    width: 100%;
    padding: 10px;
}

.profile-suspected {
    width: 120px;
    position: absolute;
    left: 0;
    bottom: 20px;
}

.detailed-feedback-title {
    font-size: 48px;
}

.feedback-wrapper,
.report-wrapper {
    background-color: #f5f5f5;
}

.pagebreak {
    page-break-before: always;
    padding: 10px;
    page-break-before: always;
}

.feedback-header {
    padding-top: 40px;
    font-size: 2.8vw;
}

.font-style-detailed-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #000;
}

.font-style-header-detailed {
    background-color: #133f7d;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #fff;
}

.mandatory-skills-wrapper {
    margin: 20px;
    background-color: #fff;
    display: flex;
}
.feedback-green {
    background: #28a745;
}

.feedback-red {
    background: #e96f68;
}

.feedback-note {
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.64px;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 2px;
    width: auto;
    display: inline-block;
}
.mandatory-skills-outer-wrapper,
.optional-skills-outer-wrapper {
    background-color: #133f7d;
    min-height: 400px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.final-remark-header,
.title-1 {
    transform: rotate(-90deg);
    white-space: nowrap;
}

.title-600 {
    padding: 10px;
    display: none !important;
}

.mandatory-skills-content {
    padding: 10px 10px 10px 30px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
}

.opyional-skills-wrapper {
    background-color: #fff;
    margin: 20px;
    display: flex;
}

.opyional-skills-content {
    padding-left: 30px;
    padding-top: 10px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
}

.optional-skills-outer-wrapper-600 {
    display: none !important;
    padding: 10px;
}

.container-1 {
    border: 2px solid red;
    display: flex;
    height: 400px;
    width: 100%;
}

.con-1 {
    border: 2px solid #00f;
    height: 100%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.con-2 {
    border: 2px solid green;
    height: 100%;
    width: 85%;
}

.font-styling-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #000;
}

.code-snippet-span,
.font-style-header-code-snippet {
    font-weight: 600;
    font-style: normal;
    line-height: 44px;
    letter-spacing: 0.04em;
}

.font-style-header-code-snippet {
    background-color: #133f7d;
    font-size: 2.2vw;
    text-align: center;
    color: #fff;
}

.code-snippet-wrapper {
    background-color: #f5f5f5;
    padding-bottom: 20px;
}

.code-snippet-header {
    background-color: #133f7d;
    color: #fff;
    margin-top: 24px;
    width: 45%;
    padding: 10px 10px 10px 7%;
}

.code-snippet-span {
    background-color: #133f7d;
    font-size: 2.8vw;
    color: #fff;
}

.final-remark-content-span,
.font-style-soft-skill {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: #000;
}

.coding-feedback-container {
    display: flex;
    gap: 10px;
}

.coding-feedback {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: normal;
    text-align: right;
}

.coding-feedback-value {
    color: #f70910;
}

.coding-answer {
    border-radius: 17px;
    background: #c3c3c3 !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.code-content {
    background: #d9d9d9;
    border-radius: 50px;
    margin: 20px;
    padding: 40px 60px 40px 89px;
}

.code-content-pre {
    width: 100%;
    overflow-x: scroll;
}

.final-remark-wrapper,
.psycological-skill-wrapper {
    margin: 20px;
    padding: 10px;
    display: flex;
}

.soft-skill-header-outer,
.vertical-left-header {
    background-color: #133f7d;
    min-height: 300px;
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.final-remark-header-600,
.psycological-skill-header-600 {
    display: none !important;
    padding: 5px;
}

.final-remark-content {
    background-color: #fff;
    padding-left: 10px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
}

.final-remark-content-span {
    font-size: 14px;
    padding: 20px;
}

.screenshots-header {
    background-color: #133f7d;
    padding: 10px 10px 10px 7%;
    width: 40%;
}

.screenshots {
    height: 100%;
    width: 100%;
}

.footer-logo {
    margin: auto;
    width: 98px;
    height: 40px;
    object-fit: contain;
}

.screenshot-wrapper-code {
    padding-top: 20px;
    position: relative;
    margin: 0 4%;
    text-align: center;
    display: inline-block;
    width: 42%;
}

.screenshot-wrapper {
    display: inline-block;
    margin: 2%;
    position: relative;
    text-align: left;
    width: 29%;
}

.screenshot-wrapper img,
.screenshot-wrapper-code img {
    width: 100%;
}

.fill-img1 {
    height: 72%;
    left: 12%;
    position: absolute;
    top: 12%;
    width: 76%;
}

.btn-g,
.section {
    position: relative;
}

.App,
.wrapper {
    background: #f5f5f5;
}

.font-style-soft-skill {
    font-size: 12px;
    margin: 10px;
}

.font-style-header,
.font-style-header-screening,
.font-style-header-soft-skill,
.phychological-skills-spanv2 {
    line-height: 44px;
    color: #fff;
    letter-spacing: 0.04em;
    font-style: normal;
}

.font-style-header-soft-skill {
    font-weight: 600;
    font-size: 28px;
    background: #133f7d;
}

.soft-skill-wrapper,
.soft-skill-wrapperv2 {
    margin: 20px;
    display: flex;
}

.soft-skill-header-600 {
    display: none;
    padding: 5px;
}

.soft-skill-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 10px 30px;
    text-align: start;
    width: 100%;
    background: #fff;
}

.soft-skill-header-inner {
    padding: 50% 0;
    margin: 0;
}

.psycological-skill-content {
    padding: 15px 10px 22px;
    background-color: #fff;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
}

.psycological-skill-header-outer {
    background-color: #133f7d;
    min-height: 350px;
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.screening-ques-wrapper {
    margin: 24px 0;
    background-color: #f5f5f5;
    padding-bottom: 1px;
}
.ratings-div {
    margin: 24px 0;
    background-color: #f5f5f5;
    padding-bottom: 1px;
}
.font-style-header-screening {
    font-weight: 600;
    font-size: 2.8vw;
}

.screening-ques-header {
    background-color: #133f7d;
    padding: 10px 10px 10px 7%;
    text-align: left;
    width: 45%;
}

.ans,
.ques {
    padding: 15px;
}

.ques-ans-wrapper {
    margin: 20px 40px;
}

.ques {
    background: #cadff5;
    width: 70%;
    font-weight: 700;
}

.ans,
.blank-div {
    background-color: #fff;
}

.ans {
    margin: 15px 0;
}

.header {
    display: flex;
    padding: 10px 30px;
}

* {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.pagebreak-after {
    page-break-after: always;
}

.img-logo {
    width: 180px;
    height: 74px;
    object-fit: contain;
}

.text-center {
    text-align: center !important;
}

.img-div {
    width: 50%;
}

.header-content-wrapper {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.blank-div {
    height: 72px;
    width: 100%;
}

.font-style-header,
.phychological-skills-spanv2 {
    font-weight: 600;
    font-size: 32px;
    text-align: right;
}

.font-style {
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.04em;
    color: #000;
}

.font-style-profile-span,
.font-syle-profile {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 27px;
}

.content-div {
    height: 24%;
    width: 100%;
    background-color: #133f7d;
    padding: 10px 10px 10px 9%;
    clip-path: polygon(100% 100%, 0% 100%, 10% 0%, 100% 0%);
}

.content-div-span {
    margin-left: 15%;
}

.btn-g {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #fff;
    background: #11b2b8;
    border-radius: 5px;
    border: none;
    right: 10px;
    white-space: nowrap;
    padding: 6px 19px;
}

.section {
    display: flex;
    justify-content: space-between;
}

.profile,
.profile-details {
    flex-direction: column;
    display: flex;
}

.tech-details-wrapper {
    display: flex;
    width: 50%;
}

.tech-details {
    width: 50%;
    position: absolute;
    top: 36%;
    left: 2%;
}

.profile {
    position: relative;
    align-items: flex-end;
    padding-top: 30px;
}

.font-syle-profile {
    font-weight: 500;
    text-align: left;
}

.font-style-profile-span {
    font-weight: 600;
    color: #626262;
}

.profile-details {
    position: absolute;
    top: 77%;
    left: 21%;
    align-items: center;
    padding-top: 7px;
}

.profile-details-p1 {
    color: #626262;
}

.profile-details-p2 {
    color: #133f7d;
    font-weight: 1000;
    font-size: 16px;
}

.profile-details-p3 {
    color: #000;
    font-weight: 600;
}

.arrow-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 25px;
    margin-top: 27px;
}

.arrow-img {
    position: relative;
    right: 22%;
    width: 6%;
}

.recommendation {
    display: flex;
    margin-top: 5px;
}

.recommendation-span {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6vw;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.04em;
    display: table-cell;
    vertical-align: middle;
    color: #fff;
}

.recommendation-div {
    width: 27%;
    height: 46px;
    display: table;
    border-radius: 13px;
    position: absolute;
    bottom: 50;
}

.red {
    background-color: #e96f68;
    left: 10%;
    z-index: 2;
}

.yellow {
    left: 35%;
    background: #f5e659;
    z-index: 1;
}

.candidate-overall-skills {
    display: flex;
    margin-top: 88px;
}

.candidate-skills-wrapper {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 50px 50px 0;
    text-align: center;
    width: 50%;
    overflow: hidden;
}

.candidate-skills-wrapper-v2 {
    background: #fff;
    text-align: center;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
}

.toplayer-ellip-img {
    width: 120px;
    height: auto;
}
.toplayer-ellip-img.resume {
    margin-left: auto;
    margin-right: auto;
}

canvas#PercentageChart {
    width: 120px !important;
    height: auto !important;
}
canvas#DataMatrixChart {
    width: 92px !important;
    height: auto !important;
}
.resumePercentage {
    position: absolute;
    font-size: 30px;
    font-weight: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #133f7d;
}
.resumePercentage.small {
    font-size: 20px;
}
.resumePercentage.large {
    font-size: 45px;
}
.candidate-skills {
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 15px auto;
    display: flex;
    max-width: 450px;
    max-height: 450px;
    align-items: center;
    justify-content: center;
}

.overall-skills-div {
    display: flex;
    min-height: 550px;
    justify-content: center;
    margin: 60px 0;
}

.overall-skills-div-v2 {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    padding: 0px 20px;
}

.candidate-skills-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #133f7d;
    padding: 0px 10px;
    padding-top: 20px;
    display: block;
}
.candidate-skill-flex {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.overAll-skills-header {
    width: 100%;
    height: 70px;
    clip-path: polygon(0 2%, 19% 197%, 353% 0%, 0% 0%);
    position: relative;
    background-color: #133f7d;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.overAll-skills {
    width: 50%;
    height: 550px;
}

.mobile-mode {
    display: none;
}

.desktop-mode {
    display: block;
}

.overAll-skills-span {
    margin-right: 10%;
}

.toplayer-ellip-percent,
.toplayer-ellip-title {
    margin: 2.5px 5px;
    font-weight: 600;
}

.toplayer-ellip-title.OverallTitle {
    margin: 0px;
    width: 100%;
    white-space: nowrap;
}

.CircularProgressbar {
    width: 100px;
    text-align: center;
}

.CircularProgressbarDiv {
    position: relative;
    text-align: center;
    top: 80px;
}

.CircularProgressbarText {
    color: #fff;
    position: absolute;
    right: 46%;
    text-align: center;
    top: 14px;
    font-size: 30px;
}

.CircularProgressbarText p {
    margin: 0;
    font-size: 14px;
}

.toplayer-ellip-div {
    padding-top: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.toplayer-ellip-percent {
    color: #133f7d;
}

.font-style-percent {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0;
}

.vector-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
}

.vector-tl-div {
    width: 220px;
}

.vector-center {
    vertical-align: middle;
    margin-top: -160px;
}

.secondlayer-ellip {
    margin-top: 10px;
    text-align: center;
}

.vector-center-div {
    display: flex;
    justify-content: center;
    width: 100%;
}

.vector-center {
    width: 140px;
}

.secondlayer-ellip-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: red;
}

.secondlayer-ellip-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ellip-layer-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.soft-phychological-skills {
    background-color: #f5f5f5;
    margin-top: 60px;
    display: flex;
}

.chart-wrapper {
    width: 200px;
    height: 200px;
    display: inline-block;
}

.rating-div {
    width: 150px;
    overflow: clip;
    display: inline-block;
    margin-left: 30px;
}

.soft-skills {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m1-img,
.m2-img,
.m3-img {
    position: absolute;
}

.m1-img {
    left: 40%;
    top: 10%;
    width: 80px;
}

.m2-img {
    top: 46%;
    left: 46%;
    width: 57px;
}

.m3-img {
    left: 33%;
    top: 75%;
    width: 105px;
}

.marking-rating-name,
.marking-rating-namev2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #000;
    margin: 10px 5px;
    text-align: start;
    height: 50px;
    display: block;
}

.marking-rating-span {
    color: #133f7d;
    font-size: 10px;
}

.leadership-skills-wrapper,
.phychological-skills-wrapper,
.soft-skills-wrapper {
    width: 49%;
    display: inline-block;
}

.space-2 {
    margin: 40px;
    display: flex;
}

.leadership-skills-wrapperv2,
.phychological-skills-wrapperv2,
.soft-skills-wrapperv2 {
    width: 32%;
    display: inline-block;
}

.soft-skills-graph-wrapper {
    background-color: #e7ebfb;
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.soft-new-wrapper {
    background-color: #e7ebfb;
    padding: 20px;
    width: 100%;
}

.screenshots-container {
    display: block;
    text-align: left;
}

.leadership-skills-div,
.soft-skills-div {
    background-color: #133f7d;
    align-items: center;
    display: flex;
    width: 100%;
    height: 70px;
}

.leadership-skills-span,
.phychological-skills-span,
.soft-skills-span {
    font-size: 28px;
    text-align: right;
    color: #fff;
    line-height: 44px;
    letter-spacing: 0.04em;
    font-style: normal;
    font-weight: 600;
}

.soft-skills-div {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
}

.leadership-skills-div {
    clip-path: polygon(0% 100%, 15% 0%, 100% 0%, 100% 100%, 0% 100%);
    justify-content: flex-end;
}

.phychological-skills-divv2,
.skills-div {
    align-items: center;
    height: 70px;
    background-color: #133f7d;
    display: flex;
}

.phychological-skills-divv2 {
    clip-path: polygon(0% 0%, 12% 100%, 100% 100%, 88% 100%, 100% 0%);
    position: relative;
    width: 130%;
    right: 15%;
    justify-content: center;
}

.skills-div {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    width: 50%;
}

.soft-skills-span {
    margin-left: 10%;
}

.leadership-skills-span,
.phychological-skills-span {
    margin-right: 10%;
}

.phychological-skills-div {
    clip-path: polygon(0% 0%, 13% 100%, 100% 100%, 100% 0%);
    position: relative;
    background-color: #133f7d;
    align-items: center;
    display: flex;
    height: 70px;
    width: 115%;
    right: 15%;
    justify-content: flex-end;
}

.other-skills,
.other-skillsv2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.code-snippet-wrapper,
.detailed-feedback,
.detailed-feedback-list,
.screening-ques-wrapper,
.skill-rating-div {
    margin-bottom: 60px;
}

.psychological-skills-span {
    color: #fff;
    background-color: #133f7d;
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vw;
    line-height: 44px;
    letter-spacing: 0.04em;
    margin: 10px 8% 10px 10px;
}

.profilepic {
    width: 90%;
}

.rating-button {
    border-radius: 13px;
    margin: 10px !important;
    padding: 20px !important;
}

.rating-arrow {
    border: 1.5px solid #133f7d;
    height: 100%;
    width: 10px;
    border-radius: 10px;
    position: relative;
}

.rating-arrow-tab {
    top: 80%;
    width: 35px;
    height: 25px;
    position: absolute;
}

.rating-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
}

.mobile-recommendation-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.recommendation-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #626262;
}

.secondlayer-ellip .ellip-div {
    width: 160px;
    display: inline-block;
}

.toplayer-ellip {
    padding-top: 40px;
    text-align: center;
}

.individual-graph-wrapper {
    width: 25%;
    display: inline-block;
    padding: 40px 20px;
    text-align: center;
}

.ratings-graph-div {
    float: left;
    display: inline-block;
    padding: 2%;
    text-align: center;
    width: 25%;
}

.mandatory-skills-graph-wrapper {
    background: #fff;
    border-radius: 15px;
    margin: 25px;
    padding: 20px;
    text-align: center;
}

.graph-title {
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: #000;
}

.graph-descp-comment {
    font-style: normal;
    font-weight: 600;
}

.other-skillsv2 .m1-img {
    left: 150px;
    top: 12%;
    width: 54px;
}

.other-skillsv2 .m2-img {
    left: 173px;
    top: 45%;
    width: 31px;
}

.other-skillsv2 .m3-img {
    position: absolute;
    width: 82px;
    top: 75%;
}

.status-green {
    color: #61be52;
}
.status-blue {
    color: #133f7d;
}

.status-yellow {
    color: #fc8c43;
}

.status-red {
    color: #e96f68;
}

@page {
    size: A4;
    margin: 0mm;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        background: #f5f5f5 !important;
    }

    .accordion-item {
        display: block !important;
        page-break-inside: avoid; /* Prevents content breaking inside */
    }

    .accordion-body {
        display: block !important;
        visibility: visible !important;
        height: auto !important;
        overflow: visible !important;
    }

    .accordion-collapse {
        display: block !important; /* Ensures hidden sections are shown */
        visibility: visible !important;
        height: auto !important;
        overflow: visible !important;
    }

    .aptitude-section-name {
        font-size: 12px !important;
    }

    .aptitude-data {
        font-size: 12px !important;
    }

    .rating-profile {
        font-size: 14px;
    }

    .bar-chart-main {
        padding-bottom: 5px !important;
    }

    .code-content-pre {
        overflow: hidden;
    }

    .screenshot-wrapper-code {
        width: 82% !important;
    }

    .break-stop {
        page-break-inside: avoid;
        break-inside: avoid;
        padding: 5px;
    }

    .feedback-status {
        font-size: 12px;
        line-height: 20px;
    }

    .App {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: #f5f5f5 !important;
        /* page-break-after: always; */
    }

    .rating-div2 {
        display: inline-block;
        margin-left: 0px;
        overflow: clip;
        width: 100px;
    }

    .btn-g {
        display: none;
    }

    .skill-rating-div div,
    .detailed-feedback,
    .screenshots-wrapper,
    .code-snippet-wrapper {
        margin-top: 12px;
    }

    .img-logo {
        width: 129px;
        height: 53px;
        object-fit: contain;
    }

    .pagebreak {
        page-break-before: always;
    }

    .soft-skills-graph-wrapper {
        height: 220px;
    }

    .profile-div,
    .profile-div-t2 {
        min-height: 170px;
    }

    .overAll-skills-header {
        height: 50px;
    }

    .m1-img {
        position: absolute;
        left: 45%;
        top: 15%;
        width: 80px;
    }

    .mobile-mode {
        display: none;
    }

    .desktop-mode {
        display: block;
    }

    .candidate-skills canvas {
        height: 300px !important;
        width: 300px !important;
    }

    .profile-img-div {
        width: 170px;
        height: 190px;
    }

    .toplayer-ellip-img {
        width: 80px;
        height: auto;
    }
    .toplayer-ellip-img.resume {
        margin-left: auto;
        margin-right: auto;
    }
    canvas#PercentageChart {
        width: 70px !important;
        height: auto !important;
    }
    canvas#DataMatrixChart {
        width: 70px !important;
        height: auto !important;
    }
    .data-matrix-main {
        width: 100%;
        margin-bottom: 10px !important;
    }
    .data-matrix-div {
        flex-direction: column;
        gap: 20px !important;
        padding: 0px 0px;
    }
    .data-matrix-circle {
        height: 70px !important;
        width: auto !important;
    }
    .total-time-taken {
        font-size: 12px !important;
        span {
            font-size: 18px !important;
        }
    }

    .total-time-avail {
        font-size: 12px !important;
    }

    .score-bar-content {
        font-size: 10px !important;
        text-align: center;
    }

    .score-bar-sub {
        font-size: 9px !important;
        text-align: center;
    }

    .resume-summary-text {
        font-size: 20px !important;
    }

    .resume-score-header {
        font-size: 17px !important;
        span {
            font-size: 20px !important;
        }
    }

    .suspicious-activity-text {
        font-size: 10px !important;
        line-height: 12px !important;
        text-align: center;
    }
    .data-matrix-sub-div {
        flex-direction: column;
        gap: 10px !important;
        align-items: center;
        justify-content: center;
    }
    .data-matrix-div {
        flex-direction: column;
        gap: 10px !important;
        align-items: center;
        justify-content: center;
    }
    .data-matrix {
        padding: 10px 0px !important;
    }
    .bar-chart-title {
        display: none;
        /* font-size: 10px !important; */
    }
    .resumePercentage {
        position: absolute;
        font-size: 20px;
        font-weight: 500;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #133f7d;
    }
    .resumePercentage.small {
        font-size: 15px !important;
    }
    .resumePercentage.large {
        font-size: 30px !important;
    }
    .total-per {
        font-size: 12px !important;
        bottom: 4px !important;
        right: -16px !important;
    }
    .profile-img-logo {
        width: 170px;
        height: 170px;
    }

    canvas#SectionWiseBar {
        width: 250px !important;
        height: 200px !important;
    }
    .profile-details-status,
    .profile-details-status-t2 {
        height: 170px;
    }

    .profile-suspected {
        width: 90px;
        right: 50%;
        bottom: 20px;
    }

    .code-content-pre {
        overflow-x: hidden;
    }

    .screenshots-container {
        display: block;
        text-align: left;
    }

    .screenshot-wrapper {
        display: inline-block;
    }

    .screenshot-wrapper-code {
        display: inline-block;
    }

    .candidate-skills {
        width: 350px;
        height: 350px;
        max-width: 350px;
        max-height: 350px;
    }

    .profile-recommended-img,
    .profile-recommended-img-t2 {
        width: 140px;
    }

    .CircularProgressbarDiv {
        width: 240px;
    }

    .profile-recommended {
        font-size: 14px;
        width: 250px;
        line-height: 36.93px;
    }

    .candidate-skills-wrapper {
        width: 50%;
        box-shadow: none;
        border-radius: 0px 25px 25px 0px;
    }

    .candidate-skills-wrapper-v2 {
        box-shadow: none;
    }

    .mandatory-skills-graph-wrapper {
        padding: 10px !important;
        border-radius: 15px;
        box-shadow: none;
    }

    .overall-skills-div {
        min-height: 450px;
        margin: 20px 0px;
    }

    .overall-skills-div-v2 {
        margin: 5px 20px;
    }

    .soft-skill-content {
        padding: 10px 20px;
        width: 100%;
    }

    .candidate-skills {
        height: 80%;
    }

    .space-2 {
        margin: 10px;
    }

    .vector-center {
        margin-top: -100px !important;
        width: 120px !important;
    }

    .vector-tl {
        width: 30px;
    }

    .vector-tl-div {
        width: 160px;
    }

    .vector-wrapper {
        margin-top: 10px;
    }

    .secondlayer-ellip {
        margin-top: 10px;
    }

    .secondlayer-ellip .ellip-div {
        width: 120px;
    }

    .soft-skills-span,
    .phychological-skills-span,
    .phychological-skills-spanv2,
    .leadership-skills-span {
        font-size: 18px;
    }

    .font-style-header,
    .candidate-skills-heading {
        font-size: 18px;
        line-height: 30px;
    }

    .chart-wrapper,
    .chart-wrapper canvas {
        width: 150px !important;
        height: 150px !important;
        display: inline-block;
    }

    .overAll-skills {
        height: 450px;
    }

    .font-style-percent {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .phychological-skills-div,
    .phychological-skills-divv2,
    .soft-skills-div,
    .leadership-skills-div,
    .skills-div {
        height: 40px;
    }

    .graph-img {
        width: 80px;
    }

    .graph-title,
    .graph-descp-comment {
        font-size: 12px;
        line-height: 15px;
    }

    .graph-title {
        height: 30px;
    }

    .graph-soft-skills {
        margin-top: -20px !important;
    }

    .individual-graph-wrapper {
        display: inline-block;
        padding: 0.5% !important;
        text-align: center;
        width: 23%;
    }

    .detailed-feedback-title {
        font-size: 24px;
        line-height: 33px;
    }

    .soft-skill-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.04em;
    }

    .code-content {
        font-size: 14px;
        line-height: 19px;
    }

    .soft-skill-wrapper,
    .soft-skill-wrapperv2 {
        display: block;
    }

    .soft-skill-header-outer {
        display: none;
    }

    .soft-skill-header-600 {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }

    .profile-details-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    .profile-intId {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
    }

    .profile-contact {
        font-weight: 100;
        font-size: 15px;
        line-height: 22px;
        display: flex;
    }

    .skill-rating-div,
    .detailed-feedback,
    .screening-ques-wrapper,
    .code-snippet-wrapper,
    .detailed-feedback-list {
        margin-bottom: 0px;
    }

    .other-skillsv2 .m1-img {
        left: 115px;
        top: 15%;
        -webkit-transform: rotate(-25deg);
        transform: rotate(-25deg);
        width: 38px;
    }

    .other-skillsv2 .m2-img {
        left: 128px;
        top: 45%;
        width: 25px;
    }

    .other-skillsv2 .m3-img {
        left: 109px;
        top: 71%;
        width: 47px;
        -webkit-transform: rotate(-40deg);
        transform: rotate(40deg);
    }

    .other-skills .m1-img {
        left: 37%;
        top: 23%;
        width: 60px;
    }

    .other-skills .m2-img {
        left: 39%;
        top: 46%;
        width: 53px;
    }

    .other-skills .m3-img {
        left: 26%;
        top: 71%;
        width: 95px;
    }

    .CircularProgressbar {
        width: 80px;
        text-align: center;
    }

    .CircularProgressbarDiv {
        position: relative;
        text-align: center;
        top: 70px;
    }

    .CircularProgressbarText {
        color: #fff;
        position: absolute;
        right: 46%;
        text-align: center;
        top: 12px;
        font-size: 24px;
    }

    .CircularProgressbarText p {
        margin: 0px;
        font-size: 10px;
    }
    .resume-summary {
        font-size: 12px !important;
    }
    .profile-join-title {
        font-size: 20px;
    }
    .profile-add-info1,
    .profile-add-info2 {
        font-size: 14px;
    }
    .soft-skill-header-600 {
        display: block;
        height: 40px;
        width: 100%;
        margin-bottom: 10px;
        clip-path: none;
        padding: 0px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
    }
    .question-container {
        padding: 10px !important;
    }
    .question-sub-panel:nth-child(1) {
        width: 66% !important;
        font-size: 13px !important;
    }
    .question-sub-panel:nth-child(2) {
        width: 17% !important;
    }
    .question-sub-panel:nth-child(3) {
        width: 17% !important;
        text-align: right;
    }
    .time-taken {
        font-size: 11px !important;
    }
    .correct {
        font-size: 12px !important;
    }
    .incorrect {
        font-size: 12px !important;
    }
    .graph-status {
        font-size: 13px !important;
    }
    .suspicious-activity {
        margin-left: -12px !important;
    }
    .coding-feedback-div {
        display: block !important;
        font-size: 13px !important;
    }
    .coding-feedback-container {
        display: flex !important;
        gap: 10px !important;
    }
    .coding-feedback {
        font-size: 13px !important;
        text-align: start !important;
    }
    .common-profile-text-block {
        display: block !important;
        max-width: 400px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .common-profile-text-block {
        display: block !important;
        max-width: 612px;
    }
    .aptitude-section-name {
        font-size: 12px !important;
    }
    .aptitude-data {
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .common-profile-text-block {
        display: block !important;
        max-width: 400px;
    }
    .aptitude-section-name {
        font-size: 12px !important;
    }
    .aptitude-data {
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .skill-rating-div div,
    .detailed-feedback,
    .screenshots-wrapper,
    .code-snippet-wrapper {
        margin-top: 15px;
    }

    .resume-summary-text {
        font-size: 25px !important;
    }

    .resume-score-header {
        font-size: 17px !important;
        span {
            font-size: 20px !important;
        }
    }

    canvas#DataMatrixChart {
        width: 80px !important;
        height: auto !important;
    }
    .data-matrix-div {
        flex-direction: column;
    }
    .data-matrix-circle {
        height: 80px !important;
        width: auto !important;
    }
    .total-time-taken {
        font-size: 12px !important;
        span {
            font-size: 18px !important;
        }
    }

    .total-time-avail {
        font-size: 12px !important;
    }

    .score-bar-content {
        font-size: 13px !important;
        text-align: center;
    }

    .score-bar-sub {
        font-size: 12px !important;
        text-align: center;
    }

    .suspicious-activity-text {
        font-size: 13px !important;
        text-align: center;
    }
    .data-matrix-sub-div {
        flex-direction: column;
        gap: 10px !important;
        align-items: center;
        justify-content: center;
    }
    .data-matrix-div {
        flex-direction: column;
        gap: 10px !important;
        align-items: center;
        justify-content: center;
    }
    .data-matrix {
        padding: 15px 0px !important;
    }
    .bar-chart-title {
        font-size: 18px !important;
    }
    .bar-chart-container {
        width: 500px !important; /* Desired width */
        height: 250px !important; /* Desired height */
        position: relative; /* Required for chart.js to work correctly */
    }

    .phychological-skills-divv2,
    .phychological-skills-div {
        width: 60%;
        margin-bottom: 10px;
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
        background-color: #133f7d;
        align-items: center;
        display: flex;
        left: 0px;
        justify-content: flex-start;
    }

    .leadership-skills-span {
        margin-left: 10%;
        margin-right: auto;
    }

    .phychological-skills-span,
    .phychological-skills-spanv2 {
        margin-left: 10%;
        text-align: left;
    }

    .img-logo {
        width: 129px;
        height: 53px;
        object-fit: contain;
    }

    .pagebreak {
        page-break-before: always;
    }

    .soft-skills-graph-wrapper {
        height: 220px;
    }

    .profile-div,
    .profile-div-t2 {
        min-height: 170px;
    }

    .overAll-skills-header {
        height: 50px;
    }

    .candidate-skills canvas {
        height: 300px !important;
        width: 300px !important;
    }

    .profile-img-div {
        width: 170px;
        height: 190px;
    }

    .toplayer-ellip-img {
        width: 80px;
        height: auto;
    }

    .toplayer-ellip-img.resume {
        margin-left: auto;
        margin-right: auto;
    }

    canvas#PercentageChart {
        width: 80px !important;
        height: auto !important;
    }
    .resumePercentage {
        position: absolute;
        font-size: 20px;
        font-weight: 500;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #133f7d;
    }
    .resumePercentage.large {
        font-size: 30px !important;
    }
    .total-per {
        font-size: 12px !important;
        bottom: 4px !important;
        right: -16px !important;
    }
    .profile-img-logo {
        width: 170px;
        height: 170px;
    }

    .profile-details-status,
    .profile-details-status-t2 {
        height: 170px;
    }

    .profile-suspected {
        width: 90px;
        right: 50%;
        bottom: 20px;
    }

    .code-content-pre {
        overflow-x: hidden;
    }

    .screenshots-container {
        display: block;
        text-align: left;
    }

    .screenshot-wrapper {
        display: inline-block;
    }

    .screenshot-wrapper-code {
        width: 50%;
        display: inline-block;
    }

    .candidate-skills {
        width: 350px;
        height: 350px;
        max-width: 350px;
        max-height: 350px;
    }

    .profile-recommended-img,
    .profile-recommended-img-t2 {
        width: 140px;
    }

    .CircularProgressbarDiv {
        width: 240px;
    }

    .profile-recommended {
        font-size: 16px;
        width: 280px;
        line-height: 36.93px;
    }

    .candidate-skills-wrapper {
        width: 50%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 25px 25px 0px;
    }

    .mandatory-skills-graph-wrapper {
        border-radius: 15px;
    }

    .overall-skills-div {
        min-height: 450px;
        margin: 20px 0px;
    }

    .overall-skills-div-v2 {
        margin: 20px 0px;
    }

    .candidate-skills {
        height: 80%;
    }

    .space-2 {
        margin: 10px;
    }

    .vector-center {
        margin-top: -100px !important;
        width: 120px !important;
    }

    .vector-tl {
        width: 30px;
    }

    .vector-tl-div {
        width: 160px;
    }

    .vector-wrapper {
        margin-top: 10px;
    }

    .secondlayer-ellip {
        margin-top: 10px;
    }

    .secondlayer-ellip .ellip-div {
        width: 120px !important;
    }

    .soft-skills-span,
    .phychological-skills-span,
    .phychological-skills-spanv2,
    .leadership-skills-span {
        font-size: 18px;
    }

    .font-style-header,
    .candidate-skills-heading {
        font-size: 18px;
        line-height: 30px;
    }

    .overAll-skills {
        height: 450px;
    }

    .font-style-percent {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .phychological-skills-div,
    .phychological-skills-divv2,
    .soft-skills-div,
    .leadership-skills-div,
    .skills-div {
        height: 50px;
    }

    .graph-img {
        width: 80px;
    }

    .graph-title,
    .graph-descp-comment {
        font-size: 12px;
        line-height: 15px;
    }

    .individual-graph-wrapper {
        display: inline-block;
        padding: 2%;
        text-align: center;
        width: 23%;
    }

    .detailed-feedback-title {
        font-size: 24px;
        line-height: 33px;
    }

    .soft-skill-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.04em;
    }

    .code-content {
        font-size: 14px;
        line-height: 19px;
    }

    .soft-skill-wrapper {
        min-height: 250px;
    }

    .profile-details-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    .profile-intId {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
    }

    .profile-contact {
        font-weight: 100;
        font-size: 15px;
        line-height: 22px;
        display: flex;
    }

    .skill-rating-div,
    .detailed-feedback,
    .screening-ques-wrapper,
    .code-snippet-wrapper,
    .detailed-feedback-list {
        margin-bottom: 0px;
    }

    .soft-skills-wrapperv2,
    .leadership-skills-wrapperv2 {
        width: 100%;
        display: inline-block;
        margin: 20px 0px;
    }

    .phychological-skills-wrapperv2,
    .phychological-skills-wrapper {
        width: 100%;
        display: inline-block;
    }

    .other-skills,
    .other-skillsv2 {
        display: block;
        margin-bottom: 0px;
    }

    .rating-div2 {
        display: inline-block;
        margin-left: 30px;
        overflow: clip;
        width: 180px;
    }

    .other-skillsv2 .m1-img {
        width: 80px;
        top: 9%;
    }

    .other-skillsv2 .m2-img {
        width: 57px;
    }

    .other-skillsv2 .m3-img {
        top: 77%;
        width: 109px;
    }

    .soft-skills-div {
        height: 40px;
        width: 60%;
        margin-bottom: 10px;
    }

    .leadership-skills-div {
        height: 40px;
        width: 60%;
        margin-bottom: 10px;
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    }

    .soft-skills-wrapper {
        width: 100%;
        display: inline-block;
        margin: 20px 0px;
    }

    .CircularProgressbar {
        width: 80px;
        text-align: center;
    }

    .CircularProgressbarDiv {
        position: relative;
        text-align: center;
        top: 70px;
    }

    .CircularProgressbarText {
        color: #fff;
        position: absolute;
        right: 46%;
        text-align: center;
        top: 12px;
        font-size: 24px;
    }

    .CircularProgressbarText p {
        margin: 0px;
        font-size: 10px;
    }

    .mobile-mode {
        display: none;
    }

    .desktop-mode {
        display: block;
    }

    .feedback-status {
        font-size: 12px;
        line-height: 20px;
    }
    .resume-summary {
        font-size: 12px !important;
    }
    .profile-join-title {
        font-size: 20px;
    }
    .profile-add-info1,
    .profile-add-info2 {
        font-size: 14px;
    }
    .question-container {
        padding: 10px !important;
    }

    .question-sub-panel:nth-child(1) {
        width: 66% !important;
        font-size: 13px !important;
    }

    .question-sub-panel:nth-child(2) {
        width: 17% !important;
    }

    .question-sub-panel:nth-child(3) {
        width: 17% !important;
        text-align: right;
    }

    .time-taken {
        font-size: 11px !important;
    }
    .correct {
        font-size: 12px !important;
    }
    .incorrect {
        font-size: 12px !important;
    }

    .graph-status {
        font-size: 13px !important;
    }

    .suspicious-activity {
        margin-left: -12px !important;
    }
    .rating-profile {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .aptitude-question-content {
        align-items: start !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important;
        justify-content: space-between !important;
        padding: 10px 20px !important;
        flex-wrap: unset !important;
        height: unset !important;
    }
    .rating-profile {
        font-size: 12px;
    }
    .bar-chart-title {
        font-size: 18px !important;
    }
    .data-matrix {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 15px;
        padding: 10px !important;
        justify-items: center;
    }
    .data-matrix-div {
        justify-content: flex-start !important;
        width: 50% !important;
    }
    .b-mob-bottom {
        border-bottom: 1px solid #aca7a7 !important;
        padding-bottom: 20px !important;
    }
    .b-mob-none {
        border-right: none !important;
    }
    .justify-mob-center {
        justify-content: center !important;
    }
    .bar-chart-container {
        width: 200px !important; /* Desired width */
        height: auto !important; /* Desired height */
        position: relative; /* Required for chart.js to work correctly */
    }
    .profile-join-title {
        font-size: 16px;
    }
    .profile-details-status {
        width: 100%;
        justify-content: flex-end;
    }
    .btn-g {
        font-weight: 500;
        font-size: 12px;
        padding: 0px 26px;
        right: 0;
    }
    .mobile-mode {
        display: block !important;
    }

    .profile-details-div-1 {
        margin: 0px 15%;
    }

    .desktop-mode {
        display: none !important;
    }

    .skills-div {
        justify-content: center;
    }

    .skills-div .soft-skills-span {
        margin-left: 0px;
    }
    .profile-add-info1,
    .profile-add-info2 {
        font-size: 12px;
    }

    .profile-add-info2 {
        margin-top: 25px;
    }
    .phychological-skills-divv2,
    .phychological-skills-div {
        height: 40px;
        width: 60%;
        margin-bottom: 10px;
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
        background-color: #133f7d;
        align-items: center;
        display: flex;
        left: 0px;
        justify-content: flex-start;
    }

    .leadership-skills-span {
        margin-left: 10%;
        margin-right: auto;
    }

    .leadership-skills-div {
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
        justify-content: flex-start;
    }

    .img-logo {
        width: 53px;
        height: 22px;
        object-fit: contain;
    }

    .profile-recommended-img,
    .profile-recommended-img-t2 {
        width: 140px;
    }

    .CircularProgressbarDiv {
        width: 200px;
    }

    .profile-img-logo {
        width: 120px;
        height: 120px !important;
    }

    .profile-img-logo,
    .profile-img-div {
        height: 140px;
        justify-content: center;
    }

    .profile-details-status,
    .profile-details-status-t2 {
        height: 140px;
    }

    .profile-suspected {
        width: 60px;
        right: 50%;
        bottom: 15px;
    }

    .profile-div,
    .profile-div-t2 {
        min-height: 120px;
        justify-content: space-between;
    }

    .profile-recommended {
        font-size: 10.5px;
        width: 200px;
        line-height: 26.38px;
        right: 50px;
    }

    .candidate-skills-wrapper {
        width: 92%;
        margin: 4%;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .radar-graph-labels-main {
        margin-top: -12px;
    }

    .soft-skills-graph-wrapper {
        width: 92%;
        margin: 4%;
    }

    .chart-wrapper {
        width: 200px;
        height: 200px;
    }

    .overall-skills-div {
        display: block;
        min-height: 350px;
        margin: 20px 0px;
    }

    .overall-skills-div-v2 {
        display: block;
        margin: 20px 0px;
    }

    .candidate-skills {
        height: 80%;
    }

    .overAll-skills {
        height: 380px;
        width: 92%;
        margin: 4%;
    }

    .space-2 {
        margin: 0px;
    }

    .toplayer-ellip-img {
        width: 70px;
        height: auto;
    }

    .toplayer-ellip-img.resume {
        margin-left: auto;
        margin-right: auto;
    }
    canvas#PercentageChart {
        width: 70px !important;
        height: auto !important;
    }
    .resumePercentage {
        position: absolute;
        font-size: 20px;
        font-weight: 500;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #133f7d;
    }
    .resumePercentage.large {
        font-size: 25px !important;
    }
    .total-per {
        font-size: 10px !important;
        bottom: 4px !important;
        right: -15px !important;
    }
    .font-style-percent {
        font-size: 14px;
        line-height: 19px;
    }

    .soft-skills-span,
    .phychological-skills-spanv2,
    .phychological-skills-span,
    .leadership-skills-span {
        line-height: 35px;
        font-size: 16px;
    }

    .phychological-skills-spanv2,
    .phychological-skills-span {
        text-align: left;
        margin-left: 10%;
    }

    .soft-skills-div {
        height: 40px;
        width: 60%;
        margin-bottom: 10px;
    }

    .leadership-skills-div {
        height: 40px;
        width: 60%;
        margin-bottom: 10px;
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    }

    .skills-div {
        height: 35px;
        width: 92%;
        margin: 4%;
        margin-bottom: 10px;
        clip-path: none;
    }

    .skills-div {
        text-align: center;
        margin: 0 auto;
    }

    .font-style-header,
    .candidate-skills-heading {
        font-size: 16px;
        line-height: 22px;
    }

    .resume-summary-text {
        font-size: 16px !important;
    }

    .resume-score-header {
        font-size: 11px !important;
        span {
            font-size: 14px !important;
        }
    }

    .other-skills,
    .other-skillsv2 {
        display: block;
        margin-bottom: 0px;
    }

    .overAll-skills-header {
        height: 35px;
        justify-content: center;
        clip-path: none;
        margin-right: inherit;
    }

    .graph-img {
        width: 80px;
    }

    .graph-title,
    .graph-descp-comment {
        font-size: 10px;
        line-height: 14px;
    }

    .individual-graph-wrapper {
        width: 33%;
        display: inline-block;
        padding: 3%;
        text-align: center;
    }

    .detailed-feedback-title {
        font-size: 24px;
        line-height: 33px;
    }

    .soft-skill-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.04em;
    }

    .font-style-soft-skill {
        padding: 2%;
    }

    .code-content {
        font-size: 14px;
        line-height: 19px;
    }

    .soft-skills-wrapper {
        width: 100%;
        display: inline-block;
        margin: 20px 0px;
    }

    .soft-skills-wrapperv2,
    .leadership-skills-wrapperv2 {
        width: 100%;
        display: inline-block;
        margin: 20px 0px;
    }

    .phychological-skills-wrapperv2,
    .phychological-skills-wrapper {
        width: 100%;
        display: inline-block;
    }

    .soft-skill-wrapper {
        min-height: auto;
        display: block;
        margin: 25px;
    }

    .soft-skill-wrapperv2 {
        min-height: auto;
        display: block;
        margin: 25px;
    }

    .screenshots-container {
        display: block;
        text-align: center;
    }

    .soft-skill-header-outer {
        display: none;
    }

    .soft-skill-header-600 {
        display: block;
        height: 40px;
        width: 100%;
        margin-bottom: 10px;
        clip-path: none;
        padding: 0px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
    }

    .soft-skill-content {
        padding: 5px;
        width: 100%;
    }

    .profile-details-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }

    .ques-ans-wrapper {
        margin: 4%;
    }

    .profile-intId {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
    }

    .feedback-status {
        font-size: 12px;
        line-height: 20px;
    }

    .profile-contact {
        font-weight: 100;
        font-size: 14px;
        line-height: 20px;
        display: flex;
    }

    .overAll-skills-span {
        margin-right: inherit;
    }

    .common-profile-text {
        color: #0d0d0d;
        font-size: 12px !important;
    }

    .screenshot-wrapper {
        width: 92%;
        padding: 4%;
        margin: 0px auto;
    }

    .screenshot-wrapper-code {
        width: 92%;
        padding: 4%;
        margin: 0px auto;
    }

    .fill-img1 {
        height: 67%;
        left: 15%;
        position: absolute;
        top: 11%;
        width: 70%;
    }

    .profile-details-div {
        width: 100%;
        padding: 4%;
        justify-content: space-around;
        display: flex;
    }

    .skill-rating-div,
    .detailed-feedback,
    .screening-ques-wrapper,
    .code-snippet-wrapper,
    .detailed-feedback-list {
        margin-bottom: 0px;
    }

    .rating-div2 {
        display: inline-block;
        margin-left: 30px;
        overflow: clip;
        width: 150px;
    }

    .other-skillsv2 .m1-img {
        width: 80px;
    }

    .other-skillsv2 .m2-img {
        width: 57px;
    }

    .other-skillsv2 .m3-img {
        width: 110px;
    }

    .other-skills .m1-img {
        left: 150px;
        top: 12%;
        width: 75px;
    }

    .other-skills .m2-img {
        left: 173px;
        top: 45%;
        width: 52px;
    }

    .other-skills .m3-img {
        position: absolute;
        width: 105px;
        left: 120px;
        top: 75%;
    }

    .CircularProgressbar {
        width: 55px;
        text-align: center;
    }

    .CircularProgressbarDiv {
        position: relative;
        text-align: center;
        top: 70px;
    }

    .CircularProgressbarText {
        color: #fff;
        position: absolute;
        right: 46%;
        text-align: center;
        top: 8px;
        font-size: 16px;
    }

    .CircularProgressbarText p {
        margin: 0px;
        font-size: 10px;
    }

    .vector-tl {
        width: 30px;
    }

    .ellip-div {
        width: 100px !important;
    }

    .vector-tl-div {
        width: 150px !important;
    }

    .secondlayer-ellip {
        margin-top: 10px;
    }

    .vector-center {
        width: 100px !important;
        margin-top: -80px !important;
    }

    .rating-system span {
        padding: 0px 15px;
        font-size: 11px;
    }

    .rating-system {
        display: inline-block;
    }

    .ratings-graph-div {
        padding: 3%;
        width: 50%;
    }
    .question-container {
        padding: 6px !important;
    }
    .question-sub-panel:nth-child(1) {
        width: 60% !important;
    }
    .question-sub-panel:nth-child(1):not(.question-sub-panel.screening_question_sub_panel) {
        font-size: 11px !important;
    }

    .question-sub-panel:nth-child(2) {
        width: 20% !important;
    }

    .question-sub-panel:nth-child(3) {
        width: 20% !important;
        text-align: right;
    }

    .time-taken {
        font-size: 9px !important;
    }

    .correct {
        font-size: 10px !important;
    }

    .incorrect {
        font-size: 10px !important;
    }

    .graph-status {
        font-size: 9px !important;
    }
    .title_mob_hide {
        display: none !important;
    }
    .screening_general_question {
        height: 35px;
    }
    .proctoring-message {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }
    .suspicious-activity {
        margin-left: -12px !important;
    }
}

@media only screen and (max-width: 500px) {
    .data-matrix-div {
        justify-content: flex-start !important;
        width: 80% !important;
    }

    .justify-mob-center {
        justify-content: center !important;
    }

    .individual-graph-wrapper {
        display: inline-block;
        padding: 3%;
        text-align: center;
        width: 50%;
    }

    .profile-recommended-img,
    .profile-recommended-img-t2 {
        width: 140px;
    }

    .CircularProgressbarDiv {
        width: 150px;
    }

    .graph-descp-comment,
    .graph-title {
        font-size: 11px;
        line-height: 14px;
    }
    .btn-g {
        padding: 0 10px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
    .graph-title {
        height: 30px;
    }
}
@media only screen and (max-width: 550px) {
    .graph-title {
        height: 28px;
    }
}

.ratings-text {
    margin: 0px;
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.96px;
    height: 40px;
}

.question-container {
    display: flex;
    justify-content: space-between;
    background: #cadff5;
    padding: 15px;
}
.question-container.aptitude {
    border-radius: 15px 15px 0px 0px;
}
.question-container-radius {
    border-radius: 10px;
}
.question-sub-panel {
    flex: 0 0 auto;
}
.question-sub-panel:nth-child(1) {
    width: 70%;
}
.question-sub-panel:nth-child(2) {
    width: 15%;
}
.question-sub-panel:nth-child(3) {
    width: 15%;
    text-align: right;
}
.time-taken {
    color: #133f7d;
    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.correct {
    color: #008000;
    font-size: 14px;
    font-weight: 700;
}
.incorrect {
    color: #f70910;
    font-size: 14px;
    font-weight: 700;
}
.graph-status {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.96px;
}
.rating-system {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.rating-system span {
    text-align: center;
    padding: 6px;
}
.m-left {
    margin-left: 10px;
}
.message-text.green {
    color: #018d3a !important;
    background-color: initial !important;
}
.message-text.orange {
    color: #fb6340 !important;
    background-color: initial !important;
}
.message-text.red {
    color: #f70910 !important;
    background-color: initial !important;
}
.proctoring-message-circle.green {
    background-color: #018d3a !important;
}

.proctoring-message-circle.orange {
    background-color: #fb6340 !important;
}

.proctoring-message-circle.red {
    background-color: #f70910 !important;
}
.start-proctoring {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.proctoring-message-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.proctoring-message-text {
    flex-grow: 1;
    border-bottom: 1px solid #626262;
    padding: 10px;
}
.proctoring-message-text-small {
    flex-grow: 1;
    border-bottom: 1px solid #626262;
    padding: 10px;
    font-size: 12px;
}
.proctoring-message {
    max-width: 700px;
    margin: 15px auto;
}
.proctoring-screenshots .message-text {
    font-size: 13px;
}
@media screen and (max-width: 500px) {
    .proctoring-message {
        margin: 15px !important;
    }
    .profile-recommended-img,
    .profile-recommended-img-t2 {
        width: 120px;
    }
    .coding-feedback-container {
        display: block;
        padding: 10px 0px;
    }
    .coding-feedback {
        font-size: 11px;
    }
    .coding-feedback-div {
        display: block !important;
    }
    .coding-answer {
        border-radius: 0px !important;
    }
    .coding-comment {
        padding: 2% !important;
    }
}
.proctoring-screenshots {
    max-width: 960px;
    margin: 15px auto;
    text-align: center;
}
.screenshots-proctoring {
    display: inline-block;
    width: 280px;
    border-radius: 10px 10px 0px 0px;
    background: #e9e9e9;
    margin: 10px;
    font-size: 14px;
}
.screenshots-description,
.screenshots-proctoring img {
    width: 280px;
    margin: auto;
    text-align: left;
}
.screening_general_question {
    background: #133f7d;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 15px 0 25px 0;
    color: #fff;
    white-space: nowrap;
}
.coding-feedback-status {
    color: #000;
}
.coding-feedback-div {
    justify-content: space-between;
    display: flex;
}
.coding-comment {
    padding: 15px;
    background: #f0f0f0;
}
.coding-blue {
    color: #133f7d !important;
}
.rating-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2%;
    gap: 10px;
    width: 100%;
    max-width: 240px;
}
.rating-text {
    color: white;
    font-weight: 800;
    text-align: center;
}
.resume-score-header {
    color: #000;
    text-align: center;
    font-size: 17.5px;
    font-weight: 500;
    span {
        color: #133f7d;
        font-size: 24px;
        font-weight: 700;
    }
}
.data-matrix {
    border-radius: 15px;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding: 27px 36px;
}
.data-matrix-3 {
    grid-template-columns: repeat(3, 1fr) !important;
}

.data-matrix-main {
    padding: 0 20px;
    width: 100%;
    margin-bottom: 20px;
}

.data-matrix-div {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #aca7a7;
    padding: 0px 4px;
    width: 100%;
}

.data-matrix-circle {
    height: 92px;
    width: auto;
}

.data-matrix-line {
    height: 92px;
    width: 1px;
    background: #aca7a7;
    color: transparent;
}

.total-time-taken {
    color: black;
    font-size: 16px;
    font-weight: 500px;
    span {
        font-size: 24px;
    }
}

.total-time-avail {
    color: #565656;
    font-size: 15px;
    font-weight: 500;
}

.score-bar-content {
    color: #133f7d;
    font-size: 16px;
    font-weight: 700;
}

.score-bar-sub {
    color: #565656;
    font-size: 15px;
    font-weight: 500;
}

.suspicious-activity-text {
    color: #133f7d;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bar-chart-main {
    border-radius: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 5px;
}

.bar-chart-title {
    font-size: 23.304px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.resume-summary-text {
    font-size: 35px;
}

.bar-chart-container {
    width: 700px; /* Desired width */
    height: 400px; /* Desired height */
    position: relative; /* Required for chart.js to work correctly */
}

.total-per {
    font-size: 15px;
    right: -20px;
    bottom: 10px;
}
.proctoring-message .proctoring-message-text {
    width: 90%;
}
.proctoring-message .proctoring-message-text-small {
    width: 90%;
}
.bar-chart-size-v1 {
    width: 350px !important;
    height: 300px !important;
}
.bar-chart-size-v2 {
    width: 250px !important;
    height: 210px !important;
}
.correct-ans-color {
    color: #008000;
}
/* Content within the container */
.aptitude-question-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    height: 50px;
    padding: 0px 20px;
}
/* Section name styling */
.aptitude-section-name {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    flex: 1;
}
/* Data styling (marks, time taken) */
.aptitude-data {
    font-size: 14px;
    font-weight: bold;
    color: black;
    flex: 1; /* Flexibility for equal spacing */
    text-align: center;
}
/* Button styling */
.aptitude-button-container {
    flex: 1;
    text-align: right;
}
.aptitude-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
}
.aptitude-button:hover {
    background-color: #0056b3;
}
.aptitude-accordion .accordion-button {
    background: none;
    border: none;
    box-shadow: none;
    color: inherit;
    font-size: inherit;
    padding: 0;
    padding-right: 20px;
}
.aptitude-accordion .accordion-button:focus {
    box-shadow: none;
}
.aptitude-accordion .accordion-item {
    border: none;
}
.aptitude-question-container {
    margin-bottom: 16px;
}
.aptitude-accordion .accordion-header {
    background-color: #cadff5;
}
.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg); /* Rotates arrow to the right */
}
.accordion-button::after {
    transform: rotate(-90deg); /* Rotates arrow to the right */
}
.accordion-button {
    padding-right: 20px; /* Adds padding to the right of the arrow */
}
.conversation-container {
    margin: 0px 0px 20px 0px !important;
}
.conversation {
    position: relative;
    margin: 0px !important;
    padding: 10px;
    background-color: #ffffff;
}
.conversation-question {
    padding: 10px;
    background-color: #e0f3ff;
    border-radius: 0px 8px 8px 8px;
    margin: 0px 0px 10px 10px;
    margin-left: 70px;
    margin-right: 25%;
}
/* .conversation-question:after {
    z-index: 10000000;
    transform: rotate(-180deg);
    position: absolute;
    top: 10px;
    content: '';
    width: 0;
    height: 0;
    border-top: 15px solid rgba(25, 147, 147, 0);
    border-left: 15px solid #e0f3ff;
    left: 100px;
} */
.conversation-transcript {
    padding: 10px;
    background-color: rgb(243, 244, 246);
    border-radius: 8px 0px 8px 8px;
    margin-bottom: 10px;
    margin-left: 25%;
    margin-right: 70px;
}
.conversation-transcript-tech {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
}
.conversation-ai-comment {
    padding: 10px;
    background-color: #d3f8eb;
    border-radius: 8px;
}
.conversation p {
    margin: 0;
}
.conversation strong {
    display: block;
    margin-bottom: 5px;
}
.conversation-tech-div {
    background-color: rgb(243, 244, 246);
    margin-left: 25%;
    margin-right: 70px;
    padding: 10px;
    border-radius: 8px 0px 8px 8px;
}
.profile-img-div-conversation-left {
    width: 40px;
    height: 40px;
    float: left;
    background: transparent;
    display: flex;
    align-items: center;
    margin: 0 15px;
}
.profile-img-div-conversation-right {
    width: 40px;
    height: 40px;
    float: right;
    background: transparent;
    display: flex;
    align-items: center;
    margin: 0 15px;
    top: 0;
    right: 0;
    position: absolute;
}
.profile-img-logo-conversation {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
@media screen and (max-width: 767px) {
    .profile-img-div-conversation-left,
    .profile-img-div-conversation-right {
        width: 25px !important;
        height: 25px !important;
        margin: 0 5px !important;
    }
    .profile-img-logo-conversation {
        width: 25px !important;
        height: 25px !important;
    }
    .conversation-question {
        margin-left: 35px !important;
        margin-right: 15% !important;
    }
    .conversation-transcript,
    .conversation-tech-div {
        margin-left: 15% !important;
        margin-right: 35px !important;
    }
    .conversation p,
    .conversation strong {
        font-size: 11px !important;
    }
    .data-matrix-3 {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 15px !important;
        justify-items: center !important;
    }
}

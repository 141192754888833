.modal {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #191924a3;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.open {
    display: flex !important;
}

.modal-container {
    width: 550px;
    max-height: 600px;
    min-height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #13161b;
    padding: 25px;
    border: 1px solid #ffffff;
}

.modal-type-1-container {
    width: 350px;
    height: 275px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: #13161b;
    padding: 25px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background: none;
    border: none;
}

.header-text {
    font-weight: 700;
    font-size: 24px;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
    padding-bottom: 8px;
}

.header-type-1-text {
    color: rgba(245, 249, 255, 0.95);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 8px;
}

.title-line {
    height: 1px;
    width: 80%;
    margin-left: 50px;
    background-color: rgba(94, 98, 103, 1);
}

.modal-content-container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 20px;
    max-height: 385px;
}

.modal-type-1-content-container {
    color: #c3d0e5;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;
    text-align: center;
}

.content-img {
    width: 135px;
    height: 140px;
}

.modal-type-1-button {
    width: 140px;
    height: 48px;
    border-radius: 8px;
    padding: 8px 16px !important;
    border: 1px solid #626262 !important;
    background: #12b6bc !important;
    color: rgba(245, 249, 255, 0.95) !important;
    font-size: 16px;
    font-weight: 600;
}

.primary-btn {
    border: 1px solid #6b7d99 !important;
    background: transparent !important;
}

.modal-button {
    width: 160px;
    height: 48px;
    background: #12b6bc !important;
    border: 1px !important;
    border-radius: 8px !important;
    color: #fffffffa !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    padding: 8px 16px !important;
}

.modal-button:disabled {
    background: #626262 !important;
}

.audio-video-prechecks-modal {
    background-color: #000000;
    border: solid 1px #fff;
    border-radius: 16px;
}
.header-text {
    font-size: 14px;
    padding: 8px 96px;
    font-weight: 400;
    padding-bottom: 16px;
}

.modal-content-container {
    align-items: stretch;
    display: block;
    margin: 0;
    padding: 0px 20px;
}

@media (max-width: 768px) {
    .audio-video-prechecks-modal.modal-container {
        background-color: #000000;
        width: 330px;
        padding: 25px;
        .header-text {
            font-size: 14px;
        }
    }
    .header-text {
        padding: 0px;
        padding-bottom: 16px;
    }

    .modal-button {
        width: 82px;
        height: 42px;
        font-size: 12px !important;
    }

    .title-line {
        width: 100%;
        margin-left: 0px;
    }

    .modal-container {
        width: 320px;
        border: none;
        padding: 32px 0px 32px 0px;
    }

    .header-text {
        font-size: 20px;
    }

    .modal-button {
        width: 142px;
        height: 42px;
        font-size: 12px !important;
    }

    .content-img {
        width: 127px;
        height: 107px;
    }

    .title-line {
        width: 100%;
        margin-left: 0px;
    }

    .modal-content-container {
        max-height: 390px;
        justify-content: flex-start;
        margin: 10px 0px;
        padding: 0px;
    }
}
